import cashImage from '@/images/payments/cash.webp'
import payOnlineGif from '@/images/payments/payment_options_gif.gif'

export enum PAYMENT_OPTIONS {
  PAY_NOW,
  COD,
  CREDIT,
  CSC,
}

export const paymentDrawerData = [
  {
    title: 'pay_now',
    desc: 'pay_now_desc',
    image: payOnlineGif,
    type: PAYMENT_OPTIONS.PAY_NOW,
  },
  {
    title: 'cash_on_delivery',
    desc: 'cash_on_delivery_desc',
    image: cashImage,
    type: PAYMENT_OPTIONS.COD,
  },
]

export const enum PAYMENT_STATUS {
  SUCCESS = 'Success',
}

export enum HYP_PAYMENT_MODE {
  CREDIT = 7,
  CASH = 1,
  PAY_NOW = 10,
  CSC = 11,
}
