import { OndcAxiosConfig } from '@dehaat/kisan-app-bl/axios/configure'
import { captureException } from '@sentry/nextjs'
import { RawAxiosRequestHeaders } from 'axios'

import {
  ACCESS_TOKEN_KEY,
  ASSUMED_USER_COOKIE,
  LAT_LNG_COOKIE_NAME,
} from '@/constants/common'
import { getCookieValue } from '@/utils/helper'

import { getClientName } from '../external'

export const OndcConfig: OndcAxiosConfig = {
  appCode: 'dehaat_farmer_web',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  ondcUrl: process.env.NEXT_PUBLIC_ONDC_URL!,
  requestInterceptorSuccessCb: (axiosConfig) => {
    if (typeof window !== 'undefined') {
      const token = getCookieValue(ACCESS_TOKEN_KEY)
      const latLong = getCookieValue(LAT_LNG_COOKIE_NAME)
      const assumedUser = getCookieValue(ASSUMED_USER_COOKIE)
      const clientName = getClientName()

      if (token && axiosConfig.headers) {
        ;(axiosConfig.headers as RawAxiosRequestHeaders)['X-AUTH-SCHEME'] =
          'keycloak'
        ;(axiosConfig.headers as RawAxiosRequestHeaders).Authorization =
          `Bearer ${token}`
      }
      if (latLong && axiosConfig.headers) {
        ;(axiosConfig.headers as RawAxiosRequestHeaders)['X-LATLONG'] = latLong
      }
      if (clientName && axiosConfig.headers) {
        ;(axiosConfig.headers as RawAxiosRequestHeaders)['X-CLIENT-NAME'] =
          clientName
      }
      if (assumedUser && axiosConfig.headers) {
        ;(axiosConfig.headers as RawAxiosRequestHeaders)['ASSUMED-USER'] =
          assumedUser
      }
    }
  },
  responseInterceptorErrCb: (error) => {
    if (
      !error.response ||
      (error.response.status < 400 && error.response.status >= 500)
    ) {
      captureException(error)
    }
  },
}
