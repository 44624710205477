import {
  CLIENT_NAME_COOKIE,
  NAVIGATION_FROM_APP_CODE,
} from '@/constants/common'

import { getCookieValue } from './helper'
import getClientCode from './helpers/getClientCode'

export function getClientName() {
  const appCode = getCookieValue(NAVIGATION_FROM_APP_CODE)
  const clientName = getCookieValue(CLIENT_NAME_COOKIE)

  if (clientName) return clientName

  return getClientCode(appCode)
}
