import fetchAllOndcVendors from '@dehaat/kisan-app-bl/api/fetchAllOndcVendors';
import { FULFILLMENT_TYPE, Vendor } from '@dehaat/kisan-app-bl/models/Vendor';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COOKIE_MAX_AGE_1YEAR, CURRENT_VENDOR_KEY, ONDC_VENDOR_ID_COOKIE, ONDC_VENDOR_KEY, VENDOR_ID_COOKIE_NAME } from '@/constants/common';
import CurrentLocation from '@/models/Location';
import { setLocalStorageKey } from '@/utils/helper';
import getVendors from '@/utils/helpers/getVendors';
import { RootState } from '../index';
export const fetchHyperlocalData = createAsyncThunk('location/fetchHyperlocalData', async ({
  latLng,
  language,
  vendorId,
  isCSCUser = false,
  forCreditOrder = false
}: {
  latLng: string;
  language?: string;
  vendorId?: string;
  isCSCUser?: boolean;
  forCreditOrder?: boolean;
}) => {
  const [dehaatVendors, ondcVendors] = await Promise.all([getVendors(latLng, language, undefined, undefined, forCreditOrder), fetchAllOndcVendors(latLng, language)]);
  setLocalStorageKey(ONDC_VENDOR_KEY, ondcVendors.length > 0 ? ondcVendors[0] : null);
  document.cookie = `${ONDC_VENDOR_ID_COOKIE}=${ondcVendors.length > 0 ? ondcVendors[0].id : null}; max-age=${COOKIE_MAX_AGE_1YEAR}; path=/`;
  let currentVendor: Vendor | null = null;
  if (dehaatVendors.length > 0) {
    const availableVendor = vendorId ? dehaatVendors.find(v => v.id == vendorId) : undefined;
    if (vendorId && availableVendor) {
      currentVendor = availableVendor;
    } else {
      const filteredVendors = dehaatVendors?.filter(vendor => vendor?.fulfillment_type === FULFILLMENT_TYPE.BOTH || vendor?.fulfillment_type === FULFILLMENT_TYPE.DOORSTEP_DELIVERY);
      currentVendor = isCSCUser && filteredVendors.length > 0 ? filteredVendors[0] : dehaatVendors[0];
    }
    setLocalStorageKey(CURRENT_VENDOR_KEY, currentVendor);
    document.cookie = `${VENDOR_ID_COOKIE_NAME}=${currentVendor ? currentVendor.id : null}; max-age=${COOKIE_MAX_AGE_1YEAR}; path=/`;
  } else {
    setLocalStorageKey(CURRENT_VENDOR_KEY, null);
    document.cookie = `${VENDOR_ID_COOKIE_NAME}=${null}; max-age=${COOKIE_MAX_AGE_1YEAR}; path=/`;
  }
  return {
    currentVendor,
    dehaatVendors,
    ondcVendors
  };
});
export interface LocationSlice {
  loadingHyperlocalData: boolean;
  currentLocation?: CurrentLocation;
  currentVendor?: Vendor | null;
  ondcVendor?: Vendor | null;
  vendorsList: Vendor[];
  ondcVendorsList: Vendor[];
}
const initialState: LocationSlice = {
  loadingHyperlocalData: true,
  vendorsList: [],
  ondcVendorsList: []
};
const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLoadingHyperlocalData: (state, action: PayloadAction<boolean>) => {
      state.loadingHyperlocalData = action.payload;
    },
    setCurrentLocation: (state, action: PayloadAction<CurrentLocation>) => {
      state.currentLocation = action.payload;
    },
    setCurrentVendor: (state, action: PayloadAction<Vendor>) => {
      state.currentVendor = action.payload;
    },
    setOndcVendor: (state, action: PayloadAction<Vendor>) => {
      state.ondcVendor = action.payload;
    },
    setVendorsList: (state, action: PayloadAction<Vendor[]>) => {
      state.vendorsList = action.payload;
    },
    setOndcVendorsList: (state, action: PayloadAction<Vendor[]>) => {
      state.ondcVendorsList = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchHyperlocalData.pending, state => {
      state.loadingHyperlocalData = true;
    });
    builder.addCase(fetchHyperlocalData.fulfilled, (state, action) => {
      const {
        currentVendor,
        dehaatVendors,
        ondcVendors
      } = action.payload;
      state.currentVendor = currentVendor;
      state.ondcVendor = ondcVendors.length > 0 ? ondcVendors[0] : null;
      state.vendorsList = dehaatVendors;
      state.ondcVendorsList = ondcVendors;
      state.loadingHyperlocalData = false;
    });
    builder.addCase(fetchHyperlocalData.rejected, state => {
      state.currentVendor = null;
      state.ondcVendor = null;
      state.vendorsList = [];
      state.ondcVendorsList = [];
      state.loadingHyperlocalData = false;
    });
  }
});
export const {
  setLoadingHyperlocalData,
  setCurrentLocation,
  setCurrentVendor,
  setOndcVendor,
  setVendorsList,
  setOndcVendorsList
} = locationSlice.actions;
export const selectLoadingHyperlocalData = (state: RootState) => state.location.loadingHyperlocalData;
export const selectLatLng = (state: RootState) => state.location.currentLocation?.latLng;
export const selectCurrentLocation = (state: RootState) => state.location.currentLocation;
export const selectCurrentVendor = (state: RootState) => state.location.currentVendor;
export const selectCurrentVendorId = (state: RootState) => {
  return state.location.currentVendor ? state.location.currentVendor.id.toString() : undefined;
};
export const selectOndcVendor = (state: RootState) => state.location.ondcVendor;
export const selectOndcVendorId = (state: RootState) => state.location.ondcVendor ? state.location.ondcVendor.id.toString() : undefined;
export const selectVendorsList = (state: RootState) => state.location.vendorsList;
export const selectOndcVendorsList = (state: RootState) => state.location.ondcVendorsList;
export default locationSlice;