import checkForCreditInsuranceAvailability from '@dehaat/kisan-app-bl/api/checkForCreditInsuranceAvailability';
import updateUserDetails from '@dehaat/kisan-app-bl/api/updateUserDetails';
import { DEFAULT_LANG, DEFAULT_LANG_NAME } from '@dehaat/kisan-app-bl/constants/common';
import User from '@dehaat/kisan-app-bl/models/User';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { IS_CREDIT_USER, LANG_UPDATE_KEY, LOCALE_COOKIE_NAME, VENDOR_ID_COOKIE_NAME, WEB_SOURCE_KEY } from '@/constants/common';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import { fetchHyperlocalData, selectLatLng } from '@/store/features/locationSlice';
import fetchUserDetails from '@/utils/api/fetchUserDetails';
import { getCookieValue, getLocalStorageKey, isCSCUser, isDigiAcreUser, removeLocalStorageKey, setLocalStorageKey } from '@/utils/helper';
import { AuthContext } from './AuthProvider';
interface ContextProps {
  user?: User;
  isLoading: boolean;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  isCreditUser: boolean;
}
export const UserContext = createContext<ContextProps>({
  isLoading: false,
  setUser: () => {
    /* nop */
  },
  isCreditUser: false
});
interface Props {
  children: ReactNode;
}
/**
 * A React provider for providing User related information to its children.
 *
 * @author Yogender Jangir <yogender.jangir@agrevolution.in>
 */
const UserProvider = ({
  children
}: Props) => {
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const [user, setUser] = useState<User>();
  const [isCreditUser, setIsCreditUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const latLng = useAppSelector(selectLatLng);
  const {
    locale: lang,
    query: {
      v
    }
  } = useRouter();
  const dispatch = useAppDispatch();

  /**
   * To be triggered on mounting of the component to fetch user details
   * and to set the state of the component accordingly
   */
  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      const createOrSetProfile = async () => {
        // Get the user first, check if the profile is set
        // If it is set, then (update the user state in context) else (create the profile and then update user, see the note below)
        // NOTE: Beware!!! this will create a profile with the language from cookies
        try {
          let user: User | null;
          user = await fetchUserDetails();
          if (user && !user.profile) {
            // Create profile with the language set in cookies
            const lang = getCookieValue(LOCALE_COOKIE_NAME) || DEFAULT_LANG;
            user = await updateUserDetails({
              [LANG_UPDATE_KEY]: lang
            }, isCSCUser() ? Number(process.env.NEXT_PUBLIC_CSC_WEB_SOURCE_KEY) : WEB_SOURCE_KEY);
          }
          if (user) {
            setUser(user);
            Sentry.setUser({
              email: user.email || '',
              username: `${user.username} - (${user.phone_number || ''}`,
              id: user.id
            });
          } else {
            throw 'Could not update user';
          }
        } catch (e) {
          Sentry.captureException(e);
          console.error(e);
        }
      };
      createOrSetProfile();
    }
  }, [isAuthenticated]);
  useEffect(() => {
    const updateCreditInsuranceAvailibility = async ({
      latLng,
      language
    }: {
      latLng: string;
      language?: string;
    }) => {
      const availibility = await checkForCreditInsuranceAvailability(latLng, ['CR'], (language as keyof typeof DEFAULT_LANG_NAME));
      if (availibility?.CR) {
        const {
          is_enabled,
          is_enrolled
        } = availibility.CR;
        const forCreditOrder = is_enabled || is_enrolled;
        if (forCreditOrder) {
          const isCreditSessionActive = getLocalStorageKey(IS_CREDIT_USER) == '1';
          if (!isCreditSessionActive) {
            setLocalStorageKey(IS_CREDIT_USER, 1);
            await dispatch(fetchHyperlocalData({
              latLng: latLng,
              language: lang || DEFAULT_LANG,
              vendorId: v ? (v as string) : getCookieValue(VENDOR_ID_COOKIE_NAME),
              isCSCUser: isDigiAcreUser(),
              forCreditOrder: true
            }));
          }
          setIsCreditUser(forCreditOrder);
        } else {
          removeLocalStorageKey(IS_CREDIT_USER);
          setIsCreditUser(false);
        }
      }
    };
    if (isAuthenticated && latLng) {
      updateCreditInsuranceAvailibility({
        latLng: latLng || '',
        language: lang
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, latLng]);
  return <UserContext.Provider value={{
    user,
    isLoading,
    setUser,
    isCreditUser
  }} data-sentry-element="unknown" data-sentry-component="UserProvider" data-sentry-source-file="UserProvider.tsx">
      {children}
    </UserContext.Provider>;
};
export default UserProvider;