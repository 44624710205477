import * as Sentry from '@sentry/nextjs';
import CustomEventTypes from '../analytics/customEventTypes';
import { trackCustomEvent } from '../analytics/googleAnalytics';
export const askNotificationPermission = async () => {
  if (typeof window !== 'undefined' && 'Notification' in window) {
    try {
      const permission = await window.Notification.requestPermission();
      if (permission === 'granted') {
        trackCustomEvent(CustomEventTypes.NOTIFICATION_PERMISSION_GRANTED);
      } else {
        trackCustomEvent(CustomEventTypes.NOTIFICATION_PERMISSION_DENIED);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }
};
export const isNotificationPermissionAvailable = () => {
  if (typeof window !== 'undefined' && 'Notification' in window) {
    if (Notification.permission === 'default') {
      return false;
    } else {
      return true;
    }
  }
};