import {
  configureLocusAxiosInstance,
  configureOndcAxiosInstance,
} from '@dehaat/kisan-app-bl/axios/configure'
import configureGraphQlClient from '@dehaat/kisan-app-bl/graphql'
import Axios from 'axios'

import { khetiConfig } from './khetiConfig'
import { locusConfig } from './locusConfig'
import { OndcConfig } from './ondcConfig'

// GraphQl client for client-side config
configureGraphQlClient({
  endPoint: `${process.env.NEXT_PUBLIC_API_BASE_URL}/graphql/`,
})

configureOndcAxiosInstance(OndcConfig)
configureLocusAxiosInstance(locusConfig)

const axios = Axios.create({
  baseURL: khetiConfig.khetiUrl,
})

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common['APP-CODE'] = khetiConfig.appCode

axios.interceptors.request.use(
  async (config) => {
    const copy = { ...config }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    khetiConfig.requestInterceptorSuccessCb!(copy)
    return config
  },
  (error) => Promise.reject(error),
)

axios.interceptors.response.use(
  (response) => {
    if (response.data.error == null) {
      return response
    }
    return Promise.reject(response.data.error)
  },
  (error) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    khetiConfig.responseInterceptorErrCb!(error)
    return Promise.reject(error)
  },
)

export default axios
