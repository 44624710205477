import User from '@dehaat/kisan-app-bl/models/User';
import { FULFILLMENT_TYPE, Vendor } from '@dehaat/kisan-app-bl/models/Vendor';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useCallback, useEffect, useState } from 'react';
import ErrorModal from '@/components/ErrorModal';
import Okay from '@/components/modalActions/Okay';
import { CART_TYPE } from '@/constants/cart';
import { AVAILABLE_STOCK, CURRENT_VENDOR_KEY, INSURANCE_KEY, IS_PREBOOK_FLOW, MY_CART, ONDC_VENDOR_KEY, OUT_OF_STOCK_PRODUCTS } from '@/constants/common';
import { allServicesProductVariantIds } from '@/constants/shriProgram';
import { useAppSelector } from '@/hooks/reduxHooks';
import useErrorModalState from '@/hooks/useErrorModalState';
import { DELIVERY_TYPE } from '@/models/Cart';
import { CartData, CartState, CartStateInStorage } from '@/models/Product';
import { selectCurrentLocation } from '@/store/features/locationSlice';
import { captureCartEvent } from '@/utils/analytics';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { CaptureUpdateCartEvent, trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import { isCartEmpty, isOnlyServicesInCart, isPrebookExitRoute, isShriCardProductInCart, isShriProgramProduct, updateCartInLocalStorage } from '@/utils/cart';
import { createUUID, getLocalStorageKey, isSessionStorageAvailable, removeLocalStorageKey, setLocalStorageKey } from '@/utils/helper';
interface CartUpdateParams {
  variantId: string;
  quantity: number;
  index?: number;
  type?: ProductListTypeEnum;
  listName?: string;
  variantPrice: number;
  variantDiscount: number;
  name?: string;
  brandName?: string;
  catalogueId: number;
}
interface Context {
  cartId: string;
  cartItems: Record<string, number>;
  currentVendor: Vendor | null;
  currentVendorId: string | null;
  pickupFarmerDetails?: User;
  resetCart: (items?: Record<string, number>, keepShriProduct?: boolean) => void;
  setPickupFarmerDetails: (farmer: User | undefined) => void;
  updateCart: (params: CartUpdateParams, isAddToCart: boolean) => void;
  validateVendor: (availableVendorId: string | null, isAddToCart?: boolean, params?: CartUpdateParams) => boolean;
  updateVendorDetails: (vendor: Vendor | null) => void;
  vendorId: string | null;
  cartType: CART_TYPE;
  isPrebookFlow: boolean;
  isPickupOrder: boolean;
  deliveryType?: DELIVERY_TYPE;
  setDeliveryType: Dispatch<SetStateAction<DELIVERY_TYPE | undefined>>;
}
export const CartContext = createContext<Context>({
  cartId: '',
  cartItems: {},
  currentVendor: null,
  currentVendorId: null,
  pickupFarmerDetails: undefined,
  resetCart: () => undefined,
  setPickupFarmerDetails: () => undefined,
  updateCart: () => undefined,
  validateVendor: () => false,
  updateVendorDetails: () => undefined,
  vendorId: null,
  isPrebookFlow: false,
  cartType: CART_TYPE.DEHAAT,
  isPickupOrder: false,
  deliveryType: DELIVERY_TYPE.HOME_DELIVERY,
  setDeliveryType: () => {
    /**/
  }
});
const emptyCartData: CartData = {
  cartId: '',
  cartItems: {},
  currentVendorId: null,
  currentVendor: null,
  pickupFarmerDetails: undefined,
  vendorId: null,
  catalogueIds: {}
};
export const cartInitialState = {
  [CART_TYPE.DEHAAT]: emptyCartData,
  [CART_TYPE.ONDC]: emptyCartData
};
const CartProvider = ({
  children
}: PropsWithChildren<Record<never, never>>) => {
  const {
    asPath,
    query,
    pathname
  } = useRouter();
  const currentLocation = useAppSelector(selectCurrentLocation);
  const cartType = asPath?.includes('ondc') ? CART_TYPE.ONDC : CART_TYPE.DEHAAT;
  const [cartState, setCartState] = useState<CartState>(cartInitialState);
  const [isPrebookFlow, setIsPrebookFlow] = useState(false);
  const {
    cartId,
    cartItems,
    currentVendorId,
    currentVendor,
    pickupFarmerDetails,
    vendorId
  } = cartState[cartType];
  const [deliveryType, setDeliveryType] = useState<DELIVERY_TYPE | undefined>(DELIVERY_TYPE.HOME_DELIVERY);
  const {
    t
  } = useTranslation('common');
  const {
    errorModalState,
    setErrorModalState,
    hideErrorModal
  } = useErrorModalState();
  const updateCartState = useCallback((key: keyof CartData, value: unknown) => {
    setCartState(prev => ({
      ...prev,
      [cartType]: {
        ...prev[cartType],
        [key]: value
      }
    }));
  }, [cartType]);
  const updateVendorDetails = useCallback((vendor: Vendor | null) => {
    if (isSessionStorageAvailable(window)) {
      sessionStorage.removeItem(AVAILABLE_STOCK);
      sessionStorage.removeItem(OUT_OF_STOCK_PRODUCTS);
    }
    setCartState(prev => {
      const dataWithVendor = {
        ...prev[cartType],
        currentVendorId: vendor ? vendor.id.toString() : null,
        currentVendor: vendor,
        vendorFulfillmentType: vendor?.fulfillment_type
      };
      return {
        ...prev,
        [cartType]: dataWithVendor
      };
    });
  }, [cartType]);
  const setPickupFarmerDetails = useCallback((user: User | undefined) => updateCartState('pickupFarmerDetails', user), [updateCartState]);
  const addToCart = useCallback((params: CartUpdateParams) => {
    if (typeof window !== 'undefined' && window.scq) {
      const utmParams = sessionStorage.getItem('utm_params');
      if (utmParams) {
        try {
          if (JSON.parse(utmParams).utm_source === 'sharechat') {
            window.scq('Add to cart', 'pre_defined');
          }
        } catch (error) {
          console.error('Failed to parse utm_params from sessionStorage:', error);
        }
      }
    }
    const {
      variantId,
      quantity,
      catalogueId
    } = params;
    captureCartEvent({
      eventType: 'add_to_cart',
      ...params
    });
    setCartState(prev => {
      const newQuantity = (prev[cartType].cartItems[variantId] || 0) + quantity;
      const updatedItems = {
        ...prev[cartType].cartItems,
        [variantId]: newQuantity
      };
      const updatedcatalogueIds = {
        ...prev[cartType].catalogueIds
      };
      if (catalogueId) {
        updatedcatalogueIds[variantId] = catalogueId;
      }
      updateCartInLocalStorage(cartType, updatedItems, null, '', !allServicesProductVariantIds.includes(variantId) ? isPrebookFlow : undefined, updatedcatalogueIds);
      return {
        ...prev,
        [cartType]: {
          ...prev[cartType],
          cartItems: updatedItems,
          catalogueIds: updatedcatalogueIds
        }
      };
    });
  }, [cartType, isPrebookFlow]);
  const removeFromCart = useCallback((params: CartUpdateParams) => {
    const {
      variantId,
      quantity
    } = params;
    CaptureUpdateCartEvent({
      eventType: 'remove_from_cart',
      ...params
    });
    setCartState(prev => {
      if (prev[cartType].cartItems[variantId]) {
        let updatedItems = {
          ...prev[cartType].cartItems
        };
        const updatedCatalogueIds = {
          ...prev[cartType].catalogueIds
        };
        const newQuantity = prev[cartType].cartItems[variantId] - quantity;
        if (newQuantity > 0) {
          updatedItems = {
            ...prev[cartType].cartItems,
            [variantId]: newQuantity
          };
        } else {
          delete updatedItems[variantId];
          delete updatedCatalogueIds[variantId];
        }
        updateCartInLocalStorage(cartType, updatedItems, null, '', isOnlyServicesInCart(updatedItems) ? false : isPrebookFlow, updatedCatalogueIds);
        return {
          ...prev,
          [cartType]: {
            ...prev[cartType],
            cartItems: updatedItems,
            catalogueIds: updatedCatalogueIds
          }
        };
      }
      return prev;
    });
  }, [cartType, isPrebookFlow]);

  // keep services in cart when cart.isPrebook is changed and remove other products
  // else clear the cart
  const resetCart = useCallback((items?: Record<string, number>, removeServicesInCart = true) => {
    const uuid = createUUID();
    let updatedItems = items || {};
    if (!removeServicesInCart && isShriCardProductInCart(cartItems, true)) {
      const itemsInCart = Object.keys(cartItems);
      const servicesInCard = (itemsInCart.filter(id => isShriProgramProduct(id, true)) as string[]);
      const serviceItems = servicesInCard.reduce((acc: Record<string, number>, productId) => {
        acc[productId] = cartItems[productId];
        return acc;
      }, {});
      updatedItems = {
        ...serviceItems,
        ...items
      };
    }
    updateCartInLocalStorage(cartType, updatedItems, currentVendorId, uuid, isPrebookFlow);
    removeLocalStorageKey(INSURANCE_KEY);
    setCartState(prev => {
      const {
        currentVendorId
      } = prev[cartType];
      const newData = {
        ...prev[cartType],
        cartId: uuid,
        vendorId: currentVendorId,
        cartItems: updatedItems || {}
      };
      return {
        ...prev,
        [cartType]: newData
      };
    });
  }, [cartItems, cartType, currentVendorId, isPrebookFlow]);
  const onCartReset = useCallback((params?: CartUpdateParams, removeServicesInCart = true) => {
    trackCustomEvent(CustomEventTypes.CART_RESET);
    const items = params != null ? {
      [params.variantId]: params.quantity
    } : {};
    if (params) {
      captureCartEvent({
        eventType: 'add_to_cart',
        ...params
      });
    }
    resetCart(items, removeServicesInCart);
    if (isSessionStorageAvailable(window)) {
      sessionStorage.removeItem(AVAILABLE_STOCK);
      sessionStorage.removeItem(OUT_OF_STOCK_PRODUCTS);
    }
    hideErrorModal();
  }, [hideErrorModal, resetCart]);
  const validateVendor = useCallback((availableVendorId: string | null, isAddToCart = false, params?: CartUpdateParams) => {
    if (vendorId !== availableVendorId) {
      setErrorModalState({
        allowClose: true,
        message: t('vendor_changed_msg'),
        show: true,
        additionalInfo: isAddToCart ? params : undefined
      });
      return false;
    }
    return true;
  }, [setErrorModalState, t, vendorId]);

  // it will only validate flow if user is not on the cart page.
  const validateFlow = useCallback((isAddToCart = false, params?: CartUpdateParams) => {
    const cartData = getLocalStorageKey<CartStateInStorage, null>(MY_CART, null);
    if (cartData && isOnlyServicesInCart(cartData[cartType].items)) {
      return true;
    }

    // if in prebook flow and user tries to add normal booking product
    // or vice versa, show error modal
    if (cartData && isPrebookFlow !== Boolean(cartData[cartType].isPrebook) && Object.keys(cartData[cartType].items).length > 0 && asPath !== '/cart') {
      setErrorModalState({
        allowClose: true,
        message: t('order_type_changed_msg', {
          orderType: cartData[cartType].isPrebook ? t('prebook') : t('normal_booking')
        }),
        show: true,
        additionalInfo: isAddToCart ? params : undefined,
        removeServicesInCart: false
      });
      return false;
    }
    return true;
  }, [cartType, isPrebookFlow, asPath, setErrorModalState, t]);
  const updateCart = useCallback((params: CartUpdateParams, isAddToCart: boolean) => {
    if (validateVendor(currentVendorId, isAddToCart, params) && validateFlow(isAddToCart, params)) {
      return isAddToCart ? addToCart(params) : removeFromCart(params);
    }
  }, [addToCart, removeFromCart, validateVendor, currentVendorId, validateFlow]);

  // Set Delivery type on change on Current Vendor
  useEffect(() => {
    const type = currentVendor?.fulfillment_type !== FULFILLMENT_TYPE.PICKUP_FROM_STORE ? DELIVERY_TYPE.HOME_DELIVERY : DELIVERY_TYPE.SELF_PICKUP;
    setDeliveryType(type);
  }, [currentVendor?.fulfillment_type]);

  // Updates the current vendor if the cart is empty or not initialized
  useEffect(() => {
    const cartData = getLocalStorageKey<CartStateInStorage, null>(MY_CART, null);
    if (cartData == null || Object.keys(cartData[cartType].items).length === 0) {
      const uuid = createUUID();
      updateCartInLocalStorage(cartType, {}, currentVendorId, uuid, isPrebookFlow, {});
      setCartState(prev => {
        const {
          currentVendorId
        } = prev[cartType];
        const newData = {
          ...prev[cartType],
          cartId: uuid,
          vendorId: currentVendorId
        };
        return {
          ...prev,
          [cartType]: newData
        };
      });
    }
  }, [currentVendorId, asPath, cartType, isPrebookFlow]);

  // Set cart items from local storage
  useEffect(() => {
    const cartData = getLocalStorageKey<CartStateInStorage, null>(MY_CART, null);
    if (cartData != null) {
      setCartState(prev => {
        const newData = {
          ...prev[cartType],
          cartId: cartData[cartType].cartId,
          vendorId: cartData[cartType].vendorId || null,
          cartItems: (cartData[cartType].items as Record<string, number>),
          catalogueIds: cartData[cartType].catalogueIds
        };
        return {
          ...prev,
          [cartType]: newData
        };
      });
    }
  }, [asPath, cartType]);

  // Reset error modal state on route change
  useEffect(() => {
    const savedVendor = getLocalStorageKey<Vendor, null>(cartType === CART_TYPE.DEHAAT ? CURRENT_VENDOR_KEY : ONDC_VENDOR_KEY) || null;
    updateVendorDetails(savedVendor);
  }, [asPath, cartType, updateVendorDetails, currentLocation]);
  useEffect(() => {
    setErrorModalState(prev => prev.show ? {
      ...prev,
      show: false
    } : prev);
  }, [asPath, setErrorModalState]);
  useEffect(() => {
    if (query.prebook == 'true') {
      setLocalStorageKey(IS_PREBOOK_FLOW, true);
      setIsPrebookFlow(true);
    } else {
      const isPrebookFlow = getLocalStorageKey(IS_PREBOOK_FLOW);
      if (isPrebookFlow && isPrebookExitRoute(pathname)) {
        setLocalStorageKey(IS_PREBOOK_FLOW, false);
        setIsPrebookFlow(false);
      } else {
        setIsPrebookFlow(Boolean(isPrebookFlow));
      }
    }
  }, [pathname, query?.prebook]);
  useEffect(() => {
    const isPrebook = getLocalStorageKey(IS_PREBOOK_FLOW);
    const cartData = getLocalStorageKey<CartStateInStorage, null>(MY_CART) || null;
    if (cartData && (isCartEmpty(cartData[cartType].items) || isOnlyServicesInCart(cartData[cartType].items))) {
      setLocalStorageKey<CartStateInStorage>(MY_CART, {
        ...cartData,
        [cartType]: {
          ...cartData[cartType],
          isPrebook: Boolean(isPrebook)
        }
      });
    }
  }, [asPath, cartType]);
  return <CartContext.Provider value={{
    cartId,
    cartItems,
    currentVendor,
    currentVendorId,
    pickupFarmerDetails,
    resetCart,
    setPickupFarmerDetails,
    updateCart,
    validateVendor,
    updateVendorDetails,
    vendorId,
    isPrebookFlow,
    cartType,
    deliveryType,
    setDeliveryType,
    isPickupOrder: deliveryType === DELIVERY_TYPE.SELF_PICKUP
  }} data-sentry-element="unknown" data-sentry-component="CartProvider" data-sentry-source-file="CartProvider.tsx">
      {children}
      <ErrorModal {...errorModalState} onClose={hideErrorModal} data-sentry-element="ErrorModal" data-sentry-source-file="CartProvider.tsx">
        <Okay handleClick={() => {
        onCartReset((errorModalState.additionalInfo as CartUpdateParams | undefined), errorModalState?.removeServicesInCart);
      }} data-sentry-element="Okay" data-sentry-source-file="CartProvider.tsx" />
      </ErrorModal>
    </CartContext.Provider>;
};
export default CartProvider;