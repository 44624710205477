import { LocusAxiosConfig } from '@dehaat/kisan-app-bl/axios/configure';
import { captureException } from '@sentry/nextjs';
import { RawAxiosRequestHeaders } from 'axios';
import { ACCESS_TOKEN_KEY, LAT_LNG_COOKIE_NAME } from '@/constants/common';
import { getCookieValue } from '@/utils/helper';
import { getClientName } from '../external';
export const locusConfig: LocusAxiosConfig = {
  appCode: 'dehaat_farmer_web',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  locusUrl: process.env.NEXT_PUBLIC_LOCUS_URL!,
  requestInterceptorSuccessCb: axiosConfig => {
    if (typeof window !== 'undefined') {
      const token = getCookieValue(ACCESS_TOKEN_KEY);
      const latLong = getCookieValue(LAT_LNG_COOKIE_NAME);
      const clientName = getClientName();
      if (token && axiosConfig.headers) {
        ;
        (axiosConfig.headers as RawAxiosRequestHeaders)['X-AUTH-SCHEME'] = 'keycloak';
        (axiosConfig.headers as RawAxiosRequestHeaders).Authorization = `Bearer ${token}`;
      }
      if (latLong && axiosConfig.headers) {
        ;
        (axiosConfig.headers as RawAxiosRequestHeaders)['X-LATLONG'] = latLong;
      }
      if (clientName && axiosConfig.headers) {
        ;
        (axiosConfig.headers as RawAxiosRequestHeaders)['X-CLIENT-NAME'] = clientName;
      }
    }
  },
  responseInterceptorErrCb: error => {
    if (!error.response || error.response.status < 400 && error.response.status >= 500) {
      captureException(error);
    }
  }
};