export const COMMON = {
  TOKEN_CHECK_INTERVAL: 30000,
  MIN_VALIDITY_BEFORE_EXPIRY: 60,
}

export const GROWTH_ADD_KEY = 'growth_address'
export const GROWTH_VARIANT = 'growth_variant'
export const CURRENT_LOCATION_KEY = 'current_location'
export const RECENT_SEARCHES_KEY = 'recent_searches'
export const DEFAULT_CURRENCY = '₹'
export const LAT_LNG_COOKIE_NAME = 'LAT_LNG'
export const LAT_LNG_URL_PARAM = 'lat_lng'
export const ONDC_VENDOR_KEY = 'ondc_vendor'
export const VENDOR_ID_COOKIE_NAME = 'VENDOR_ID'
export const ONDC_VENDOR_ID_COOKIE = 'ONDC_VENDOR_ID'
export const CURRENT_VENDOR_KEY = 'current_vendor'
export const LOCALE_COOKIE_NAME = 'NEXT_LOCALE'
export const LANG_UPDATE_KEY = 'language_code'
export const COOKIE_MAX_AGE_1YEAR = 31536000
export const SELECTED_ADDRESS_KEY = 'selected_address'
export const SELECTED_ADDRESS_ONDC_KEY = 'selected_address_ondc'
export const BILLING_ADDRESS_ONDC_KEY = 'billing_address_ondc'
export const APP_LINK = 'https://dehaat-kisan.app.link/OKyRAx3K4Fb'
export const NEARBY_DC_RANGE = 30
export const FARM_ADVISORY_SUPPORT = '18001036110'
export const PRODUCTS_QUERY_LIMIT = 9
export const PRODUCTS_QUERY_OPEN = 'y'
export const DEHAAT_WHATSAPP_SUPPORT_NUMBER1 = '8506909095'
export const DEHAAT_WHATSAPP_SUPPORT_NUMBER2 = '8527006424'
export const MY_CART = 'my_cart_v1' // Updated the cart key
export const REDIRECT_URI_KEY = 'redirect_uri'
export const USER_CROPS_KEY = 'user_crops'
export const USER_SOWING_DATES_KEY = 'sowing_dates'
export const ACCESS_TOKEN_KEY = 'kc_token'
export const REFRESH_TOKEN_KEY = 'kc_refresh'
export const NAVIGATION_FROM_APP = 'fromApp'
export const NAVIGATION_FROM_APP_CODE = 'appCode'
export const APP_MAIN_PAGE_IDENTIFIER = 'parentPage'
export const DEFAULT_APP_DESCRIPTION =
  'Dehaat: Transform your farming experience with our online marketplace. Quality products and expert guidance at your fingertips for a prosperous harvest.'
export const DEFAULT_WELCOME_DEHAAT = 'देहात में आपका स्वागत है'
export const DEFAULT_SELECT_LANGUAGE = 'अपनी भाषा चुनें'
export const INSURANCE_KEY = 'insurance'
export const IS_PREBOOK_FLOW = 'is_prebook_flow'
export const ORDER_ID_KEY = 'order_id'
export const BOOKING_ID_KEY = 'booking_id'
export const OUT_OF_STOCK_PRODUCTS = 'out_of_stock_products'
export const IS_CREDIT_USER = 'c_user'
export const AVAILABLE_STOCK = 'available_stock'
export const GraphQLAppVersionHeaders = {
  'X-APP-VERSION': '269',
}
export const SHRI_PROGRAM_USER_DETAILS = 'shri_program_details'

export const MIN_TOKEN_VALIDITY_MINUTE = 1

export const GET_WHATSAPPHANDLER_API = (
  message: string,
  supportNumber?: '1' | '2',
) => {
  const url = `https://api.whatsapp.com/send/?phone=+91${
    supportNumber === '2'
      ? DEHAAT_WHATSAPP_SUPPORT_NUMBER2
      : DEHAAT_WHATSAPP_SUPPORT_NUMBER1
  }&text=${encodeURI(message)}`
  return url
}

export const VIDEO_CALL_LINK = 'https://bot.agrevolution.in/videocall-crop'
export const WEBSITE_NAME = 'DeHaat Kisan: Agriculture and Farming'
export const APP_NAME = 'DeHaat Kisan: Farming Guide'
export const WEBSITE_URL = 'https://dehaat.in'
export const HOME_PAGE_TITLE =
  'Online Agriculture Marketplace for Farmers | Dehaat'

export const SHOW_SOWING_DATE = true
export const WEB_SOURCE_KEY = 24

export enum FeedTabs {
  HOME,
  COMMUNITY,
  SHOP,
}

export enum POWERED_BY {
  DEHAAT = 'dehaat',
  ONDC = 'ondc',
}
export enum DeviceType {
  MOBILE,
  TABLET,
  DESKTOP,
}

export const EDIT_PRODUCT_PRICE_REASON = 'Price updated through aims app'

export const CSC_STATE = 'csc_state'

export const AUTHORICATION_HEADER = 'Bearer'

export const CSC_MERCHANT_DATE_TYPE = 'YYYY-MM-DD HH:mm:ss'

export const DIGIACRE_DOMAIN = 'digiacre.com'
export const RECENT_PRODUCT_SEARCHES_KEY = 'recent_product_searches'

export const RECENT_PRODUCT_SEARCHES_COUNT = 4
export const ASSUMED_USER_COOKIE = 'ASSUMED-USER'
export const ASSUMED_USER_NUMBER_COOKIE = 'assumed-user-number'

export const MY_SERVICE_CART = 'my_service_cart'

// Serice Booking Flow
export const SERVICE_CART = 'service_cart'
export const SERVICE_NAME = 'service_name'
export const SERVICE_ID = 'service_id'
export const SERVICE_ICON_URL = 'service_icon_url'
export const SERVICE_VENDOR_NAME = 'service_vendor_name'
export const SERVICE_VENDOR_ID = 'service_vendor_id'
export const BOOKING_MANDATORY_DATA = 'booking_mandatory_data'
export const BOOKING_OPTIONAL_DATA = 'booking_optional_data'
export const BOOKING_DETAIL = 'booking_detail'

// External Auth

export const CLIENT_NAME_COOKIE = 'X-CLIENT-NAME'
export const AGC_CLIENT_ID = 'agc-dehaat'
export const DBA_CLIENT_ID = 'business-app'

