import { Address } from '@dehaat/kisan-app-bl/models/AddressV2'
import {
  FeedProductVariant,
  ProductListFeedItem,
} from '@dehaat/kisan-app-bl/models/Feed'
import { ORDER_TYPE } from '@dehaat/kisan-app-bl/models/Order'
import { Product as ProductV2 } from '@dehaat/kisan-app-bl/models/ProductV2'
import { Vendor } from '@dehaat/kisan-app-bl/models/Vendor'
import { formatAmount } from '@dehaat/kisan-app-bl/utils/helper'
import {
  CartItems,
  CartStateInStorage,
  Product,
  Product2,
  ProductVariant,
  ProductVariantInventorySet,
} from 'src/models/Product'

import { CART_TYPE } from '@/constants/cart'
import { MY_CART } from '@/constants/common'
import { ORDER_STATUS } from '@/constants/order'
import { HYP_PAYMENT_MODE } from '@/constants/payments'
import {
  relatedServiesProductVariantIds,
  shricardProductVariantIds,
} from '@/constants/shriProgram'
import { InsuranceDataItem, PremiumOption } from '@/models/api/productInsurance'
import { AvailableCoupon, Coupon } from '@/models/Coupon'
import { InsuranceItem } from '@/models/ProductInsurance'
import { PREBOOK_EXIT_ROUTES } from '@/utils/config'

import { getLocalStorageKey, isArray, setLocalStorageKey } from './helper'

const productDiscount = (inventory_set: ProductVariantInventorySet[]) => {
  return isArray(inventory_set) ? Number(inventory_set[0].discount) || 0 : 0
}

const productPrice = (inventory_set: ProductVariantInventorySet[]) => {
  return isArray(inventory_set) ? Number(inventory_set[0].price) || 0 : 0
}

const productCatalogueId = (inventory_set: ProductVariantInventorySet[]) => {
  return isArray(inventory_set) ? inventory_set[0].c_id || 0 : 0
}

const getTotalCost = (
  variants: ProductVariant[],
  cartItems: Record<string, number>,
) => {
  return variants.reduce(
    (sum, variant) =>
      sum + productPrice(variant.inventory_set) * (cartItems[variant.id] || 0),
    0,
  )
}

const getTotalDiscount = (
  variants: ProductVariant[],
  cartItems: Record<string, number>,
) => {
  return variants.reduce(
    (sum, variant) =>
      sum +
      productDiscount(variant.inventory_set) * (cartItems[variant.id] || 0),
    0,
  )
}

const isCartEmpty = (cartItems: CartItems) => {
  return Object.keys(cartItems).length === 0
}

const getNumberOfItemsInCart = (cartItems: CartItems) =>
  Object.values(cartItems as Record<string, number>).reduce(
    (quantity, count) => quantity + count,
    0,
  )

const generateInsuranceOrderDetails = (
  totalInsuranceCost: number,
  insuranceItems: Record<string, InsuranceItem>,
  insuranceData: InsuranceDataItem[],
) => {
  // Map insuranceItems and premiums to a format that validate and create order APIs understand
  // For free insurances where purchased quantity is greater than freeQty, split it into 2 parts
  // such that [{ freeQty, cost: 0 }, { qty - freeQty, cost: total_premium_amt }]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const policy_order_details: any[] = []
  for (const variantId in insuranceItems) {
    const insuranceItem = insuranceItems[variantId]
    const premiumOptions = insuranceData
      .find((i) => Number(i.id) === Number(insuranceItem.id))
      ?.mapped_product_variant_details.find(
        (m) => Number(m.product_variant_id) === Number(variantId),
      )?.premium_options[0]
    if (premiumOptions) {
      const { cost_to_farmer, multiplier_factor, total_premium_amount } =
        premiumOptions
      const basePolicyDetails = {
        group_insurance_product_id: insuranceItem.id,
        multiplier_factor: multiplier_factor.toString(),
        product_variant: Number(variantId),
      }
      if (cost_to_farmer === 0) {
        policy_order_details.push({
          ...basePolicyDetails,
          cost_to_farmer: (0).toString(),
          units_purchased: insuranceItem.freeQty,
        })
        if (insuranceItem.qty - insuranceItem.freeQty > 0) {
          policy_order_details.push({
            ...basePolicyDetails,
            cost_to_farmer: total_premium_amount.toString(),
            units_purchased: insuranceItem.qty - insuranceItem.freeQty,
          })
        }
      } else {
        policy_order_details.push({
          ...basePolicyDetails,
          cost_to_farmer: cost_to_farmer.toString(),
          units_purchased: insuranceItem.qty,
        })
      }
    }
  }

  return {
    cost_to_farmer: totalInsuranceCost.toFixed(2),
    policy_order_details,
  }
}

const createOrderQuery = (
  isPickupOrder: boolean,
  orderCreatedFor: string,
  cartId: string,
  cartList: ProductVariant[],
  totalVariantCost: number,
  totalInsuranceCost: number,
  totalDiscount: number,
  shippingCharges: number,
  validateWithCoupon: boolean,
  cartItems: Record<string, number>,
  appliedCoupon: AvailableCoupon | null,
  selectedAddress: Address | null,
  insuranceItems: Record<string, InsuranceItem>,
  insuranceData: InsuranceDataItem[],
  idKycStatus: boolean,
  bankKycStatus: boolean,
  hashCode?: string,
  vendor?: Vendor,
  delivery_date?: string,
  isGrowthStarter?: boolean,
  cumulative_commission?: number,
  isCreditOrder?: boolean,
  payment_mode_id?: number,
) => {
  const totalPrice =
    totalVariantCost + totalInsuranceCost - totalDiscount + shippingCharges
  const hasInsuranceItems = Object.keys(insuranceItems)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cartData = getLocalStorageKey<CartStateInStorage, null>(MY_CART, null)
  const catalogueIds = cartData?.[CART_TYPE.DEHAAT].catalogueIds || {}
  const query: any = {
    type: isPickupOrder ? ORDER_TYPE.SELF_PICKUP : ORDER_TYPE.DOORSTEP,
    delivery_fee: shippingCharges.toFixed(2),
    vendor: vendor?.id,
    price: (totalVariantCost + totalInsuranceCost).toFixed(2),
    discount: totalDiscount.toFixed(2),
    total_price: totalPrice.toFixed(2).toString(),

    order_details: isGrowthStarter
      ? cartList.map((variant) => {
          const catalogueId = catalogueIds[variant.id]
          return {
            ...variant,
            c_id: catalogueId,
          }
        })
      : cartList.map((variant) => {
          const catalogueId = catalogueIds[variant.id]
          const qty = cartItems[variant.id] as number
          return {
            product_variant: Number(variant.id),
            quantity: qty,
            price: productPrice(variant.inventory_set) * qty,
            discount: productDiscount(variant.inventory_set) * qty,
            c_id: catalogueId,
          }
        }),
    cart_id: cartId,
    order_created_for: orderCreatedFor,
  }
  if (cumulative_commission) {
    query.cumulative_commission = cumulative_commission
  }

  if (isCreditOrder) {
    query.is_credit_order = isCreditOrder
    query.payment = {
      transactions: [
        {
          payment_mode: payment_mode_id || HYP_PAYMENT_MODE.CASH,
          amount: formatAmount(
            totalPrice - (appliedCoupon ? Number(appliedCoupon.discount) : 0),
          ),
        },
      ],
    }
  }

  if (delivery_date) {
    query.status = ORDER_STATUS.PreOrder
    query.booking_date = delivery_date
  }

  if (isArray(hasInsuranceItems)) {
    query.insurance = generateInsuranceOrderDetails(
      totalInsuranceCost,
      insuranceItems,
      insuranceData,
    )
  }

  if (!isPickupOrder) {
    query.address = selectedAddress?.id
    query.full_display_address = selectedAddress?.full_display_address
  }

  if (hashCode) {
    query.hash_code = hashCode
  }

  if (appliedCoupon && validateWithCoupon) {
    query.coupon = {
      code: appliedCoupon.couponCode,
      discount: appliedCoupon.discount,
    }
    query.total_price = (
      Number(totalPrice) - Number(appliedCoupon.discount)
    ).toFixed(2)
  }
  return query
}

const mergedCouponList = (
  allCoupons: Coupon[],
  availableCoupons: AvailableCoupon[],
  appliedCoupon: AvailableCoupon | null,
) => {
  // Would store only unique coupon code inside it
  const uniqueCouponCodeSet = new Set<string>()
  // Would contain list of all unique Available coupons
  let uniqueAvailableCoupons: AvailableCoupon[] = []
  availableCoupons.forEach((availableCoupon) => {
    if (!uniqueCouponCodeSet.has(availableCoupon.couponCode)) {
      uniqueCouponCodeSet.add(availableCoupon.couponCode)
      if (
        appliedCoupon &&
        availableCoupon.couponCode === appliedCoupon.couponCode
      ) {
        // If coupon is applied then it would be the first item in the list
        uniqueAvailableCoupons = [appliedCoupon, ...uniqueAvailableCoupons]
      } else {
        // If a coupon in unique it would be added at the end of the list
        uniqueAvailableCoupons.push(availableCoupon)
      }
    }
  })
  // Rest of the coupons which are not present in the unique available coupon list
  const filterAllCoupons = allCoupons.filter(
    (coupon) => !uniqueCouponCodeSet.has(coupon.couponCode),
  )
  return [...uniqueAvailableCoupons, ...filterAllCoupons]
}

const updateCartInLocalStorage = (
  cartType: CART_TYPE,
  items?: Record<string, number>,
  vendorId?: string | null,
  cartId?: string,
  isPrebook?: boolean,
  catalogueIds?: Record<string, number>,
) => {
  let cartData = getLocalStorageKey<CartStateInStorage, null>(MY_CART, null)
  if (cartData == null) {
    const data = {
      items: {},
      vendorId: '',
      cartId: '',
      isPrebook: false,
      catalogueIds: {},
    }
    cartData = { [CART_TYPE.ONDC]: data, [CART_TYPE.DEHAAT]: data }
  }
  if (items) {
    cartData = { ...cartData, [cartType]: { ...cartData[cartType], items } }
  }
  if (catalogueIds) {
    cartData = {
      ...cartData,
      [cartType]: { ...cartData[cartType], catalogueIds },
    }
  }
  if (vendorId) {
    cartData = { ...cartData, [cartType]: { ...cartData[cartType], vendorId } }
  }
  if (cartId) {
    cartData = { ...cartData, [cartType]: { ...cartData[cartType], cartId } }
  }
  if (isPrebook !== undefined) {
    cartData = { ...cartData, [cartType]: { ...cartData[cartType], isPrebook } }
  }
  setLocalStorageKey<CartStateInStorage>(MY_CART, cartData)
}

const formatProductData = (product: Product | ProductListFeedItem): Product => {
  const productWithoutVariant: Product = {
    ...product,
    product_variants: [],
  } as Product
  const productAsFeedItem: ProductListFeedItem = product as ProductListFeedItem
  if (productAsFeedItem.insurance_data) {
    productWithoutVariant.insuranceData = {
      hasInsurance: productAsFeedItem.insurance_data.has_insurance,
      label: productAsFeedItem.insurance_data.label,
    }
  }
  return {
    ...productWithoutVariant,
    product_variants: product.product_variants.map((variant) => {
      const variantAsFeedItem: FeedProductVariant =
        variant as FeedProductVariant
      if (variantAsFeedItem.insurance_data) {
        ;(variant as ProductVariant).insuranceData = {
          hasInsurance: variantAsFeedItem.insurance_data.has_insurance,
          label: variantAsFeedItem.insurance_data.label,
        }
      }
      return {
        ...variant,
        product: productWithoutVariant,
      } as ProductVariant
    }),
  } as Product
}

const formatAllProducts = (products: ProductV2[]) => {
  const result: Product[] = products.map((element) => ({
    brand: {
      id: '-1',
      name: element.brand.app_display_name,
      image_url: element.brand.image_url,
      imageUrl: element.brand.image_url,
    },
    category: {
      id: '-1',
      name: element.category.app_display_name || '',
      image_url: element.category.image_url,
      imageUrl: element.category.image_url,
      appDisplayName: element.category.app_display_name,
    },
    description: element.description || '',
    id: element.id,
    image_url: element.image_url,
    insuranceData: {
      hasInsurance: element.insurance_data.has_insurance,
      label: element.insurance_data.label,
    },
    name: element.name,
    slug: { slug: element.slug },
    product_variants: element.product_variants.map((variant) => ({
      id: variant.id,
      attribute_value: variant.attribute_value.map((attr) => ({
        name: attr.name,
        attribute: { name: attr.attribute.name, id: '-1' },
      })),
      insuranceData: {
        hasInsurance: variant.insurance_data.has_insurance,
        label: variant.insurance_data.label,
      },
      inventory_set: variant.vendor_quotes.map(({ price, discount, c_id }) => ({
        price: price,
        discount: discount,
        c_id: c_id,
      })),
    })),
  }))

  return result
}

const isProductInStock = (product: Product) =>
  product.product_variants?.some(({ inventory_set }) =>
    isArray(inventory_set),
  ) || false
const isProductInStock2 = (product: Product2) =>
  product.product_variants?.some(({ vendor_quotes }) =>
    isArray(vendor_quotes),
  ) || false

const getTotalPremiumCost = (
  insuranceItems: Record<string, InsuranceItem>,
  cartItems: CartItems,
  insuranceData: InsuranceDataItem[],
) => {
  let totalPremiumCost = 0
  for (const variantId in cartItems) {
    if (insuranceItems[variantId]) {
      const insurance = insuranceData.find(
        (i) => Number(i.id) === Number(insuranceItems[variantId].id),
      )
      if (insurance) {
        const mappedVariant = insurance.mapped_product_variant_details.find(
          (v) => Number(v.product_variant_id) === Number(variantId),
        )
        if (mappedVariant) {
          const { cost_to_farmer, total_premium_amount } =
            mappedVariant.premium_options[0]
          totalPremiumCost +=
            (insuranceItems[variantId].qty -
              insuranceItems[variantId].freeQty) *
            (cost_to_farmer === 0 ? total_premium_amount : cost_to_farmer)
        }
      }
    }
  }
  return totalPremiumCost
}

const getTotalPremiumDiscount = (
  insuranceItems: Record<string, InsuranceItem>,
  cartItems: CartItems,
  premiumData: Record<string, PremiumOption[]>,
) => {
  return Object.keys(insuranceItems).reduce((prev, variantId) => {
    if (cartItems[variantId]) {
      const insuranceData = insuranceItems[variantId]
      const insuranceId = insuranceData != null ? insuranceData.id : null
      if (
        insuranceId &&
        isArray(premiumData[variantId]) &&
        premiumData[variantId][0].gip_id === insuranceId
      )
        return (
          prev +
          insuranceData.qty *
            (premiumData[variantId][0].total_premium_amount -
              premiumData[variantId][0].cost_to_farmer)
        )
    }
    return 0
  }, 0)
}

const hasInsurance = (
  insuranceItems: Record<string, InsuranceItem>,
  cartItems: Record<string, number>,
) => {
  const variantsInCart = Object.keys(cartItems).filter(
    (variantId) => !!cartItems[variantId],
  )
  if (isArray(variantsInCart)) {
    return variantsInCart.some(
      (variantId) =>
        !!(insuranceItems[variantId] && insuranceItems[variantId].qty),
    )
  }
  return false
}

const isShriProgramProduct = (
  variantId: string,
  includeOtherServices: boolean,
) => {
  const arr = includeOtherServices
    ? shricardProductVariantIds.concat(relatedServiesProductVariantIds)
    : shricardProductVariantIds

  return arr.includes(variantId)
}

const isShriCardProductInCart = (
  items: CartItems,
  includeOtherServices = false,
) =>
  Object.keys(items).some((id) =>
    isShriProgramProduct(id, includeOtherServices),
  )

const isPrebookExitRoute = (pathName: string) =>
  PREBOOK_EXIT_ROUTES.includes(pathName)

// check if shri card and related services are the only
// products in currentItems passed or cart.items in local storage
const isOnlyServicesInCart = (items: CartItems) => {
  return Object.keys(items).every((productId) =>
    shricardProductVariantIds
      .concat(relatedServiesProductVariantIds)
      .includes(productId),
  )
}

export {
  createOrderQuery,
  formatAllProducts,
  formatProductData,
  generateInsuranceOrderDetails,
  getNumberOfItemsInCart,
  getTotalCost,
  getTotalDiscount,
  getTotalPremiumCost,
  getTotalPremiumDiscount,
  hasInsurance,
  isCartEmpty,
  isOnlyServicesInCart,
  isPrebookExitRoute,
  isProductInStock,
  isProductInStock2,
  isShriCardProductInCart,
  isShriProgramProduct,
  mergedCouponList,
  productCatalogueId,
  productDiscount,
  productPrice,
  updateCartInLocalStorage,
}
