import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
interface CommonState {
  orderRefundStatus: string;
}
const initialState: CommonState = {
  orderRefundStatus: ''
};
const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setOrderRefundStatus: (state, action: PayloadAction<string>) => {
      state.orderRefundStatus = action.payload;
    }
  }
});
export const {
  setOrderRefundStatus
} = commonSlice.actions;
export const selectOrderRefundStatus = (state: RootState) => state.common.orderRefundStatus;
export default commonSlice;