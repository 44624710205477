import * as Sentry from '@sentry/nextjs'
import { Redirect } from 'next'

import { ACCESS_TOKEN_KEY, DeviceType } from '@/constants/common'
import { CSC_LOGIN } from '@/constants/login'

import { storageInUse } from './localStorage'
import parseJWT from './parseToken'

export function getLocalStorageKey<T, K>(key: string, defaultValue?: K) {
  const value = storageInUse.getItem(key)
  return value ? (JSON.parse(value) as T) : defaultValue
}

export function setLocalStorageKey<T>(key: string, value: T) {
  storageInUse.setItem(key, JSON.stringify(value))
}

export function removeLocalStorageKey(key: string) {
  storageInUse.removeItem(key)
}

export function clearLocalStorage() {
  storageInUse.clear()
}

export function handleMultiPromiseResult<T>(
  result: PromiseSettledResult<T>,
  defaultValue: T,
) {
  return result.status === 'fulfilled' ? result.value : defaultValue
}

export function getCookieValue(key: string) {
  if (typeof window !== 'undefined') {
    const value = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${key}=`))
      ?.split('=')[1]

    // Cookie value when set to null gets read as a string and hence parsing is required
    // We are setting vendorId cookie to null in case of non hyperlocal areas
    return value === 'null' ? JSON.parse(value) : value
  }
}

export function fixNextPageUrl(url?: string | null) {
  const regex = /^http:\/\//
  if (url != null && regex.test(url)) {
    return url.replace(regex, 'https://')
  }
  return url
}

export function convertSlugToString(slug: string) {
  return slug.split('-').join(' ')
}

export function getTimeStamp(date: string) {
  const dayInS = 86400
  const hrInS = 3600
  const postDateTime = new Date(date)
  const currentDateTime = new Date()
  const diff = (currentDateTime.getTime() - postDateTime.getTime()) / 1000
  const days = Math.floor(diff / dayInS)
  const hours = Math.floor((diff % dayInS) / hrInS)
  const minutes = Math.round(((diff % dayInS) % hrInS) / 60)
  if (days === 0 && hours === 0 && minutes === 0) return 'Just Now'
  if (days === 0 && hours === 0) return `${minutes}m`
  if (days === 0) return `${hours}h`
  let diffDt = `${postDateTime.getDate()} ${postDateTime.toLocaleString(
    'default',
    {
      month: 'long',
    },
  )}`
  if (currentDateTime.getFullYear() !== postDateTime.getFullYear()) {
    diffDt += ` ${postDateTime.getFullYear()}`
  }
  return diffDt
}

export function isArray<T>(array?: T[] | null) {
  return Array.isArray(array) && array.length > 0
}

export function createUUID() {
  return `${1e7}-${1e3}-${4e3}-y${8e3}-${1e12}`.replace(/[018]/g, (c) =>
    (
      Number(c) ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))
    ).toString(16),
  )
}

export const htmlToString = (html: string) =>
  html
    .replace(/<[^>]+>/g, '')
    .trim()
    .replace(/\n/g, '')

export function getThumbnailUrl(url: string) {
  const splitArray = url.split('/')
  return `https://img.youtube.com/vi/${splitArray[splitArray.length - 1]}/0.jpg`
}

export function slugToString(slug: string) {
  return slug.split('-').join(' ')
}

const padNumberWithZero = (num: number) => {
  return num.toString().padStart(2, '0')
}

export function secondsToMinSec(inSeconds: number) {
  const minutes = Math.floor(inSeconds / 60)
  const seconds = inSeconds - minutes * 60
  return `${padNumberWithZero(minutes)}:${padNumberWithZero(seconds)}`
}

export const persistToken = (
  key: string,
  value = '',
  expiresIn = -1,
  parentDomain?: string,
) => {
  if (parentDomain) {
    document.cookie = `${key}=${value}; max-age=${expiresIn}; path=/; domain=${parentDomain}; secure; samesite=strict`
  } else {
    document.cookie = `${key}=${value}; max-age=${expiresIn}; secure; path=/; samesite=strict;`
  }
}

export const notFoundAppRedirect = (fromApp: boolean) => {
  return fromApp
    ? {
        redirect: {
          statusCode: 303,
          destination: '/app/404',
        } as Redirect,
      }
    : ({ notFound: true } as { notFound: true })
}

export const dateDiffInDays = (startDate: string, endDate: string) => {
  const millisecondsPerDay = 86400000 //24 * 60 * 60 * 1000
  const start = new Date(startDate).getTime()
  const end = new Date(endDate).getTime()
  return Math.ceil((end - start) / millisecondsPerDay)
}

export const generateDateString = (format: string) => {
  const now = new Date()
  const year = String(now.getFullYear())
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hour = String(now.getHours()).padStart(2, '0')
  const minute = String(now.getMinutes()).padStart(2, '0')
  const second = String(now.getSeconds()).padStart(2, '0')

  const formattedDate = format
    .replace('YYYY', year)
    .replace('MM', month)
    .replace('DD', day)
    .replace('HH', hour)
    .replace('mm', minute)
    .replace('ss', second)

  return formattedDate
}

export const isProductionBuild =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'

export const isCSCUser = (token?: string | null) => {
  const accessToken = token || getCookieValue(ACCESS_TOKEN_KEY)
  if (accessToken) {
    const { external_partner_id, external_partner_type } = parseJWT(accessToken)
    return external_partner_type === CSC_LOGIN.CSC && !!external_partner_id
  }
  return false
}

export const isDigiAcreUser = () => {
  return (
    typeof window !== 'undefined' &&
    window.location.origin === process.env.NEXT_PUBLIC_DIGIACRE_CSC_SUB_DOMAIN
  )
}

/**
 * Replaces the hostname of a URL with the new hostname.
 * this will be used to support multi tenancy.
 * tenants -> dehaat.in / digiacre.com
 * @param url - The URL to replace the hostname.
 * @returns The URL with the replaced hostname.
 */
export const replaceUrlHost = (url: string) => {
  // do nothing if not a digiacre user
  if (!isDigiAcreUser()) return url
  try {
    const urlObj = new URL(url)
    const prodHostname = 'dehaat.in'
    const devHostname = 'kisan-app.dehat.co'
    const newProdHostname = 'dehaat.digiacre.com'
    const newDevHostname = 'dehaat-test.digiacre.com'

    if (urlObj.hostname.includes(prodHostname)) {
      urlObj.hostname = urlObj.hostname.replace(prodHostname, newProdHostname)
    } else if (urlObj.hostname.includes(devHostname)) {
      urlObj.hostname = urlObj.hostname.replace(devHostname, newDevHostname)
    }

    return urlObj.toString()
  } catch {
    return url
  }
}

export function isValidJSON(jsonString: string) {
  try {
    JSON.parse(jsonString)
    return true
  } catch {
    return false
  }
}

export function isVideoUrl(url: string) {
  const videoRegex = /\.(mp4|avi|mov|webm|mkv|flv)$/i
  return videoRegex.test(url)
}

// returns true if the userAgent string indicates a mobile device
// required to check for device type in SSR as window.innerwidth won't be defined
export function isMobileDevice(userAgent?: string) {
  return !userAgent || /mobile/i.test(userAgent) || /tablet/i.test(userAgent)
}

export function getDeviceType(userAgent?: string): DeviceType {
  // Default to empty string if userAgent is not provided
  const ua =
    userAgent ||
    (typeof window !== 'undefined' ? window.navigator.userAgent : '')

  // Check for mobile and tablet devices
  const isMobile = /mobile/i.test(ua)
  const isTablet = /tablet/i.test(ua) || /iPad|Android/i.test(ua)

  if (isMobile) {
    return DeviceType.MOBILE
  } else if (isTablet) {
    return DeviceType.TABLET
  } else {
    return DeviceType.DESKTOP
  }
}

/**
 * Utility method to handle string, string[], or undefined
 * @param input - The input variable of type string | string[] | undefined
 * @returns - A string: the input itself if it's a string, the first item if it's a string array, or an empty string if undefined.
 */
export function resolveAsString(input: string | string[] | undefined): string {
  if (Array.isArray(input)) {
    return input[0]
  } else if (typeof input === 'string') {
    return input
  } else {
    return ''
  }
}
export function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export function postMessageToWebView(window: Window, data: object) {
  try {
    ;(window as any).ReactNativeWebView.postMessage(JSON.stringify(data))
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function isSessionStorageAvailable(window: Window | undefined): boolean {
  try {
    if (window && 'sessionStorage' in window) {
      const testKey = '__test__'
      window.sessionStorage.setItem(testKey, 'test')
      return true
    } else {
      throw new Error('Session Storage is undefined.')
    }
  } catch (error) {
    Sentry.captureException(error)
    return false
  }
}

export const isFromDFA = (appCode?: string) => appCode && appCode === '01'
export const isFromVrApp = (appCode?: string) => appCode && appCode === '03'
