import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ACCESS_TOKEN_KEY, AVAILABLE_STOCK, DIGIACRE_DOMAIN, INSURANCE_KEY, IS_CREDIT_USER, MIN_TOKEN_VALIDITY_MINUTE, MY_CART, NAVIGATION_FROM_APP, NAVIGATION_FROM_APP_CODE, OUT_OF_STOCK_PRODUCTS, REFRESH_TOKEN_KEY, SERVICE_CART, SERVICE_ID, SERVICE_VENDOR_ID, SHRI_PROGRAM_USER_DETAILS } from '@/constants/common';
import { APP_CODE_TYPE } from '@/models/Auth';
import Mixpanel from '@/utils/analytics/mixpanel';
import fetchTokenFromRefreshToken from '@/utils/api/fetchTokenFromRefreshToken';
import keycloakLogout from '@/utils/api/logout';
import { getCookieValue, isCSCUser, isSessionStorageAvailable, persistToken, postMessageToWebView, removeLocalStorageKey } from '@/utils/helper';
import { isMoengageIntialized } from '@/utils/moengage';
import parseJWT from '@/utils/parseToken';
interface AuthContextProps {
  isAuthenticated: boolean;
  isAuthReady: boolean;
  persistTokens: VoidFunction;
  logout: (redirectUrl?: string) => void;
}
export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  isAuthReady: false,
  logout: () => undefined,
  persistTokens: () => undefined
});
interface Props {
  children: ReactNode;
}
const getTokenExpiryTime = (token: string | null) => {
  if (token) {
    const {
      exp
    } = parseJWT(token);
    return new Date(exp * 1000).getTime();
  }
  return null;
};
const checkTokenExpiry = (tokenExpiryTime: number | null, minValidity = 60000) => {
  if (tokenExpiryTime != null) {
    const currentTime = new Date().getTime();
    return tokenExpiryTime - currentTime <= minValidity;
  }
  return false;
};
const minValidity = MIN_TOKEN_VALIDITY_MINUTE * 60 * 1000;
const AuthProvider = ({
  children
}: Props) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const {
    asPath,
    reload,
    pathname,
    query
  } = useRouter();
  const accessTokenExpiryTime = useMemo(() => getTokenExpiryTime(accessToken), [accessToken]);
  const refreshTokenExpiryTime = useMemo(() => getTokenExpiryTime(refreshToken), [refreshToken]);
  const logout = useCallback((redirectUrl?: string) => {
    if ('ReactNativeWebView' in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      postMessageToWebView(window, {
        code: 'ERR_001',
        message: 'logout'
      });
      return;
    }
    if (refreshToken) {
      keycloakLogout(refreshToken);
    }
    const parentDomain = isCSCUser(accessToken) ? DIGIACRE_DOMAIN : '';
    setAccessToken(null);
    setRefreshToken(null);
    persistToken(ACCESS_TOKEN_KEY, '', -1, parentDomain);
    persistToken(REFRESH_TOKEN_KEY, '', -1, parentDomain);
    clearLocalStorage();
    if (isSessionStorageAvailable(window)) {
      sessionStorage.removeItem(AVAILABLE_STOCK);
      sessionStorage.removeItem(OUT_OF_STOCK_PRODUCTS);
    }
    Mixpanel.reset();
    if (isMoengageIntialized()) {
      Moengage.destroy_session();
    }
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      reload();
    }
  }, [refreshToken, accessToken, reload]);
  const refreshTokenHandler = useCallback(async (token: string) => {
    if (checkTokenExpiry(accessTokenExpiryTime, minValidity) || checkTokenExpiry(refreshTokenExpiryTime, minValidity)) {
      const response = await fetchTokenFromRefreshToken(token, (getCookieValue(NAVIGATION_FROM_APP_CODE) as APP_CODE_TYPE) || Boolean(getCookieValue(NAVIGATION_FROM_APP)));
      if (response) {
        if ('ReactNativeWebView' in window) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          postMessageToWebView(window, {
            code: 'INFO_001',
            message: response
          });
        }
        const parentDomain = isCSCUser(response.access_token) ? DIGIACRE_DOMAIN : '';
        persistToken(ACCESS_TOKEN_KEY, response.access_token, response.expires_in, parentDomain);
        persistToken(REFRESH_TOKEN_KEY, response.refresh_token, response.refresh_expires_in, parentDomain);
        setAccessToken(response.access_token || null);
        setRefreshToken(response.refresh_token || null);
      } else {
        logout(isCSCUser(accessToken) ? process.env.NEXT_PUBLIC_DIGIACRE_APP_URL : undefined);
      }
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [accessTokenExpiryTime, refreshTokenExpiryTime, logout]);
  const persistTokens = useCallback(() => {
    setIsAuthReady(false);
    const accessToken = getCookieValue(ACCESS_TOKEN_KEY) || null;
    const refreshToken = getCookieValue(REFRESH_TOKEN_KEY) || null;
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setIsAuthReady(true);
  }, []);
  useEffect(() => {
    persistTokens();
  }, [persistTokens, pathname]);
  useEffect(() => {
    let timer: number;
    if (refreshToken) {
      refreshTokenHandler(refreshToken);
      timer = window.setInterval(() => refreshTokenHandler(refreshToken), 60000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [refreshToken, refreshTokenHandler]);

  /**
   * To be triggered everytime route changes to send
   * current page title to React Native App
   */

  useEffect(() => {
    if ('ReactNativeWebView' in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      postMessageToWebView(window, {
        code: 'TITLE',
        message: document.title
      });
    }
  }, [asPath]);

  // To be triggerd each time url changes to check for
  // UTM params and store them to be passed in GA if required
  useEffect(() => {
    // utm_source is a mandate field in case of UTM params.
    if ('utm_source' in query) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const utmParams: Record<string, any> = {
        utm_source: query.utm_source
      };
      if ('utm_medium' in query) {
        utmParams.utm_medium = query.utm_medium;
      }
      if ('utm_campaign' in query) {
        utmParams.utm_campaign = query.utm_campaign;
      }
      if ('utm_term' in query) {
        utmParams.utm_term = query.utm_term;
      }
      if ('utm_id' in query) {
        utmParams.utm_id = query.utm_id;
      }
      if ('utm_content' in query) {
        utmParams.utm_content = query.utm_content;
      }
      if ('utm_channel' in query) {
        utmParams.utm_channel = query.utm_channel;
      }
      if ('utm_adgroup' in query) {
        utmParams.utm_adgroup = query.utm_adgroup;
      }
      if ('utm_adgroupid' in query) {
        utmParams.utm_adgroupid = query.utm_adgroupid;
      }
      if ('ad' in query) {
        utmParams.ad = query.ad;
      }
      sessionStorage.setItem('utm_params', JSON.stringify(utmParams));
    }
  }, [asPath, query]);
  return <AuthContext.Provider value={{
    isAuthenticated: !!accessToken && !!refreshToken,
    isAuthReady,
    logout,
    persistTokens
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="AuthProvider.tsx">
      {children}
    </AuthContext.Provider>;
};
function clearLocalStorage() {
  removeLocalStorageKey(MY_CART);
  removeLocalStorageKey(INSURANCE_KEY);
  removeLocalStorageKey(SHRI_PROGRAM_USER_DETAILS);
  removeLocalStorageKey(SERVICE_CART);
  removeLocalStorageKey(SERVICE_ID);
  removeLocalStorageKey(SERVICE_VENDOR_ID);
  removeLocalStorageKey(IS_CREDIT_USER);
}
export default AuthProvider;