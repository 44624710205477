import fetchAllVendorsV2 from '@dehaat/kisan-app-bl/api/fetchAllVendorsV2'
import { DEFAULT_LANG } from '@dehaat/kisan-app-bl/constants/common'

const getVendors = async (
  latLong: string,
  locale?: string,
  accessToken?: string,
  serviceId?: string,
  forCreditOrder?: boolean,
) => {
  const vendors = await fetchAllVendorsV2(
    latLong,
    locale || DEFAULT_LANG,
    forCreditOrder,
    undefined,
    accessToken,
    serviceId,
  )
  if (vendors && vendors.vendor_details.length > 0) {
    return vendors.vendor_details
  } else {
    return []
  }
}

export default getVendors
